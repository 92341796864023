import axios from "axios";
import { useEffect, useState } from "react";

const useGetNextVisit = (client_uuid, park_id) => {
    const [nextVisit, setNextVisit] = useState(null);
    const GMM_URL = process.env.REACT_APP_GMM_URL; 

    useEffect(() => {
        const fetchNextVisit = async () => {
            if (!client_uuid || !park_id) {
                console.log('Missing required parameters: client_uuid or park_id');
                return;
            }
            try {
                const response = await axios.get(`https://${GMM_URL}/attendants/next_visit`, {
                    params: {
                        client_uuid,
                        park_id,
                    },
                });
                setNextVisit(response);
            } catch (error) {
                console.error(error);
            }
        };

        fetchNextVisit();
    }, [client_uuid, park_id]);

    return nextVisit;
};

export default useGetNextVisit;
