import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './Booking.module.scss';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

export const Booking = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const GMM_URL = process.env.REACT_APP_GMM_URL;
    

    const [availableTickets, setAvailableTickets] = useState({ mm: 0, tm: 0, po: 0 });
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch tickets from the API
        const fetchTickets = async () => {
            try {
                const response = await axios.get(`https://${GMM_URL}/users/purchases/tickets/pending`, {
                    params: {
                        isa_client_uuid: activeAccount.idTokenClaims.extension_isaUUID,
                    },
                });

                if (response.data.status === 'SUCCESS') {
                    setAvailableTickets(response.data.data.available_tickets);
                }

            } catch (error) {
                console.error('Error fetching tickets:', error);
            }
        };

        fetchTickets();
    }, [activeAccount.idTokenClaims.extension_isaUUID]);

    const redirectToCalendar = (resourceKey) => {
        navigate(`/calendar?resource=${resourceKey}`);
    };

    const parks = [
        { key: 'mm', name: 'Mundo Marino', spanClass: 'firstSpan', url: 'https://example.com/mundo-marino' },
        { key: 'tm', name: 'Termas Marinas Park', spanClass: 'secondSpan', url: 'https://example.com/termas-marinas-park' },
        { key: 'po', name: 'Poseidón', spanClass: 'thirdSpan', url: 'https://example.com/poseidon' }
    ];

    return (
        <section className={styles.section}>
            <h2>Reservar mi visita</h2>
            <div className={styles.entranceContainer}>
                <p><strong>Entradas disponibles</strong></p>
                <ul className={styles.parkList}>
                    {parks.map((park) => (
                        <li key={park.key}>
                            <span className={styles[park.spanClass]}>{availableTickets[park.key]}</span> {park.name}
                        </li>
                    ))}
                </ul>
                <a href={process.env.REACT_APP_TICKETS_URL} className={styles.firstButton}>Quiero más entradas</a>
            </div>
      
            <p className={styles.buttonsSubTitle}>Elegí el parque</p>
            <div className={styles.buttonsContainer}>
                {parks.map((park) => (
                    <button 
                        key={park.key}
                        className={availableTickets[park.key] > 0 ? styles.secondButton : styles.disabledButton}
                        disabled={availableTickets[park.key] === 0}
                         onClick={() => redirectToCalendar(park.key)}
                    >
                        {park.name}
                    </button>
                ))}
            </div>
        </section>
    );
};
