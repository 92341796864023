import React, { useState } from 'react';
import axios from 'axios';

import { useLocation, useNavigate, Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './CalendarSection.module.scss';
import { useMsal } from '@azure/msal-react';
import Loading from '../components/Loading'; 
import  useGetNextVisit  from '../hooks/useGetNextVisit';
import { useEffect } from 'react';

export const CalendarSection = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [specialDates, setSpecialDates] = useState([new Date(), new Date().setDate(new Date().getDate() + 1)]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isAvailable, setIsAvailable] = useState(false);
  const [loading, setLoading] = useState(false); 

  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const park_id = queryParams.get('resource');
  const GMM_URL = process.env.REACT_APP_GMM_URL;
  const client_uuid = activeAccount?.idTokenClaims?.extension_isaUUID;

  const isValidDate = (date) => {
    const today = new Date();
    const thirtyDaysFromNow = new Date(today);
    thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);

    return date > today && date <= thirtyDaysFromNow;
  }

  const nextVisit = useGetNextVisit(client_uuid, park_id);

useEffect(() => {
  // Only proceed if nextVisit and nextVisit.data are not null or undefined
  if (nextVisit && nextVisit.data && nextVisit.data.data && nextVisit.data.data.date) {
    const visitDate = new Date(nextVisit.data.data.date);
    setSpecialDates([visitDate]);
  }
}, [nextVisit]);


  const handleDateChange = (date) => {
    if (isValidDate(date)) {
      setSelectedDate(date);
      setIsAvailable(false)
      setErrorMessage(''); 
    } else {
      setErrorMessage('Por favor, seleccione una fecha que no sea hoy y que esté dentro de los próximos 30 días.');
    }
  }

  const checkAvailability = async () => {
    if (errorMessage) {
      console.error('Invalid date selected');
      return;
    }

    setLoading(true); 
    try {
      const response = await axios.get(`https://${GMM_URL}/attendants/get_availability`, {
        params: {
          park_id: park_id,
          date: selectedDate.toISOString().split('T')[0],
        },
      });
      if (response.data.data.availability === 'Y') {
        setIsAvailable(true);
        setSuccessMessage('La fecha seleccionada está disponible!');
      } else {
        setIsAvailable(false);
        setSuccessMessage('');
        setErrorMessage('La fecha seleccionada no está disponible.');
      }

    } catch (error) {
      console.error('There was an error!', error);
    } finally {
      setLoading(false); 
    }
  };

  const scheduleVisit = async () => {
    if (errorMessage) {
      console.error('Invalid date selected');
      return;
    }

    setLoading(true);
    try {
      const requestBody = {
        client_id: activeAccount.idTokenClaims.extension_isaUUID,
        date: selectedDate.toISOString().split('T')[0],
        park_id: park_id,
      };

      const response = await axios.post(`https://${GMM_URL}/attendants/schedule_visit`, requestBody);

      if (response.data.status === 'SUCCESS') {
        navigate('/success');
      }

    } catch (error) {
      console.error('There was an error!', error);
    } finally {
      setLoading(false); 
    }
  };

  return (
    <section className={styles.section}>
      <h2>Reservar mi visita</h2>
      <p>Seleccioná la fecha en la que queres visitar el parque.</p>
      <div className={styles.calendarContainer}>
        <DatePicker 
          selected={selectedDate} 
          onChange={(date) => handleDateChange(date)} 
          highlightDates={specialDates} 
          inline 
        />
      </div>
      {errorMessage && <p className={styles.error}>{errorMessage}</p>}
      {successMessage && <p className={styles.success}>{successMessage}</p>}
      <div className={styles.buttonsContainer}>
        <Link className={styles.firstButton} to='/booking'>Volver atrás</Link>
        {loading ? (
          <Loading /> 
        ) : (
          <>
            {!isAvailable && (
              <button className={styles.secondButton}
              style={{backgroundColor:process.env.REACT_APP_PRIMARY_COLOR}} onClick={checkAvailability} disabled={!!errorMessage}>
                Consultar disponibilidad
              </button>
            )}
            {isAvailable && (
              <button className={styles.secondButton} 
              style={{backgroundColor:process.env.REACT_APP_SECONDARY_COLOR}}
              onClick={scheduleVisit}>
                Confirmar reserva
              </button>
            )}
          </>
        )}
      </div>
    </section>
  );
};
